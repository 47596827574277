// src/layouts/index.js
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Chrome from '../components/Chrome';
import Header from '../components/Header';

const TemplateWrapper = ({ title, children }) => {
  return (
        <Chrome
            header={<Header title={title} />}
        >
            <div
                id="TemplateWrapper"
                style={{
                    marginBottom: '3.5em'
                }}
            >
                {children}
            </div>
        </Chrome>
    );
};

TemplateWrapper.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
};
export default TemplateWrapper;
