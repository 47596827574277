import React, { useEffect } from 'react';

import Layout from '../layouts';
import SEO from '../components/seo';
import { navigate } from 'gatsby';
import { redirectPathMap } from '../../src/constants';

let isNotFound = false;

const NotFoundPage = () => {
  useEffect(() => {
    const pathnameNoTrailingSlash = window.location.pathname.replace(/\/$/, '');
    if (!redirectPathMap[pathnameNoTrailingSlash]) {
        isNotFound = true;

        navigate('/404/');

        const htmlEl = (document || {}).querySelector('html');
        if (htmlEl.getAttribute('style')) htmlEl.removeAttribute('style');
    }
  }, []);

  return isNotFound && (
    <Layout>
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>
        As of December 2020, we updated our site hierarchy to make it easier to find the right
        information. Please use the left-hand navigation or the search bar to find the desired
        documentation.
      </p>
      <p>
        Go to the <a href="/develop-overview">Development in Interaction Studio</a> overview page.
      </p>
    </Layout>
  );
};

export default NotFoundPage;
